import '../styles/faq.scss';

import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  let len = data.length;
  for ( let i = 0; i < len; i++ ) {
    if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

let getformattedData2 = ( data, sectionsArray ) => {
  console.log( 'Inside fd2' );
  let output = [];
  let section;
  for ( let i = 0; i < sectionsArray.length; i++ ) {
    output['section_' + i] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionsArray[i] && item.node );
    for ( let j = 0; j < section.length; j++ ) {
      output['section_' + i][j] = {
        que: section[j].node.attribute_2_key,
        section: section[j].node.attribute_1_value
      };
    }
  }

  return output;
};

const getUniqueQuestions = ( data ) => {

  let uniqueQuestions = [];

  data.forEach( question => {
    let { data_category, style_category, attribute_2_key } = question.node;
    let question1 = { data_category, style_category, questionText: attribute_2_key };

    if ( style_category === 'faq_question' ) {
      uniqueQuestions.push( question1 );
    }
  } );

  return uniqueQuestions;
};

const FAQPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allStrapiPageData(filter: {page_name: {in: ["faq_page", "faq_page_3"]}}) {
          edges {
            node {
              id
              page_name
              style_category
              content
              attribute_1_key
              attribute_1_value
              attribute_2_key
              attribute_2_value
              column
              data_category
              version
            }
        }
      }
    }`
  );

  const [ showResults, setShowResults ] = React.useState( {} );
  const [ showSections, setShowSections ] = React.useState( {} );
  const [ searchQuery, changeQuery ] = useState( '' );
  const [ matchedQuestions, setMatchedQuestions ] = useState( [] );
  const [ uniqueQuestions, setUniqueQuestions ] = useState( [] );

  let pageData = data.allStrapiPageData.edges;
  let uniqueSectionData = getUniqueSectionData( pageData );
  let visited = [];
  let sectionsLength = uniqueSectionData.length;

  for ( let i = 0; i < sectionsLength; i++ ) {
    visited[uniqueSectionData[i]] = false;
  }

  useEffect( () => {
    setUniqueQuestions( getUniqueQuestions( pageData ) );
  }, [data] );

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  return (

    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                              Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                        See Categories
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-life">
                                                        About Life Insurance
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-home">
                                                        About Home Owner Insurance
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-why-bubble">
                                                        Why Bubble
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-home-life">
                                                        About Home + Life Insurance
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-application">
                                                        Application
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-premium-payments">
                                                        Premium Payments
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-policy-and-claims">
                                                        Policy and Claims
                              </Link>
                            </div>

                            <br/>

                            <div className="faq-section-headings">
                              <Link to="/faq-beneficiaries">
                                                        Beneficiaries
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout> );
};

export default FAQPage;
